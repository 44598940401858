"use strict";

var app = {
	RECAPTCHA_SITE_KEY: "6LdljXAUAAAAAMURvh_303JmHvUb2Uw0AwJeC5cI",
	FORM_SUBMIT_URL: "https://xfh9rmwdxd.execute-api.ca-central-1.amazonaws.com/development/recaptcha",
	init: function init() {
		var _this = this;

		var $contactForm = $('#contact-form');
		this.$contactForm = $contactForm;
		this.$messsageTextArea = $contactForm.find('textarea#message').first();
		this.$nameInput = $contactForm.find('input#name').first();
		this.$emailInput = $contactForm.find('input#email').first();
		this.$messageValidationError = $contactForm.find('.message-validation-error').first();
		this.$submitbutton = $contactForm.find('button[type=submit]').first();
		this.$alertBox = $contactForm.find('div.alert').first();

		// Transitions on page scroll
		this._addCssAnimation("#services .features", "expandUp");
		this._addCssAnimation("#services .pgtitle", "hatch");
		this._addCssAnimation("#services .tealstuff", "fadeIn");
		this._addCssAnimation("#contact .contact-box", "fadeIn");
		this._addCssAnimation("#contact .contactdesc", "hatch");
		this._addCssAnimation("#contact .mobile-contact-box", "hatch");

		$('#myNavbar ul li').on('click', function (element) {
			var $colllapse = $('button.navbar-toggle');
			if ($colllapse.attr('aria-expanded') == "true") {
				$colllapse.click();
			}
		});
		// Form validator
		$.validate({
			modules: 'toggleDisabled',
			form: '#contact-form',
			borderColorOnError: "",
			onSuccess: function onSuccess() {
				_this._hideAlert();
				grecaptcha.execute();
				return false;
			},
			onError: function onError() {
				_this._showAlert('warning', "\n\t\t\t\t<span class=\"glyphicon glyphicon-exclamation-sign\" aria-hidden=\"true\"></span> <strong>Oops!</strong></br></br>\n\t\t\t\tIt looks like the form is not filled properly. Double check perhaps?", 3500);
				return false;
			}
		});

		// Insert Captcha
		window.captchaCallback = function (code) {
			return _this.submitFn(code);
		};
		$('.captcha-here').html("<div class=\"g-recaptcha\"\n\t\t\tdata-sitekey=\"" + this.RECAPTCHA_SITE_KEY + "\"\n\t\t\tdata-callback=\"captchaCallback\"\n\t\t\tdata-badge=\"inline\"\n\t\t\tdata-size=\"invisible\">\n\t\t</div><script src=\"https://www.google.com/recaptcha/api.js\" async defer></script>");
	},
	submitFn: function submitFn(verifyCode) {
		var _this2 = this;

		var payload = {};
		payload['name'] = this.$nameInput.val();
		payload['email'] = this.$emailInput.val();
		payload['message'] = this.$messsageTextArea.val();
		payload['recaptcha'] = verifyCode;

		console.debug("Payload", payload);

		this.$submitbutton.text('Sending.');

		this._setFormElementsReadOnly(true);
		this._hideAlert();
		$.ajax({
			url: this.FORM_SUBMIT_URL,
			type: 'POST',
			contentType: 'application/json; charset=utf-8',
			data: JSON.stringify(payload)
		}).then(function (result) {
			_this2._showAlert('info', "\n\t\t\t<span class=\"glyphicon glyphicon-ok\" aria-hidden=\"true\"></span> <strong>Thank you for contacting us!</strong></br></br>\n\t\t\tWe will get back to you as soon as possible.", 5000);
		}).catch(function (result) {
			_this2._showAlert('danger', "\n\t\t\t<span class=\"glyphicon glyphicon-exclamation-sign\" aria-hidden=\"true\"></span> <strong>Something went wrong :(</strong></br></br>\n\t\t\tPlease email us at contact@oqpi.co or call us at 647-624-4961 for immediate reponse.");
		}).then(function (result) {
			_this2.$submitbutton.text('Submit');
			_this2.$messsageTextArea.val('');
			_this2._setFormElementsReadOnly(false);
			grecaptcha.reset();
		});
	},
	_binds: function _binds() {},
	_showAlert: function _showAlert(kind, content) {
		var _this3 = this;

		var autoHide = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

		if (document.contactFormAutoHide) clearTimeout(document.contactFormAutoHide);

		this.$alertBox.attr("class", "alert alert-" + kind);
		this.$alertBox.html(content);
		this.$alertBox.slideDown(function () {
			if (autoHide) {
				document.contactFormAutoHide = setTimeout(function () {
					return _this3._hideAlert();
				}, autoHide);
			}
		});
	},
	_hideAlert: function _hideAlert() {
		this.$alertBox.slideUp();
	},
	_addCssAnimation: function _addCssAnimation(selector, animation) {
		var _applyAnim = function _applyAnim() {
			$(selector).each(function () {
				var imagePos = $(this).offset().top;
				var topOfWindow = $(window).scrollTop();
				if (imagePos < topOfWindow + 700) {
					$(this).addClass(animation);
				}
			});
		};
		_applyAnim();
		$(window).scroll(function () {
			return _applyAnim();
		});
	},
	_setFormElementsReadOnly: function _setFormElementsReadOnly(yes) {
		this.$messsageTextArea.prop("disabled", yes);
		this.$nameInput.prop("disabled", yes);
		this.$emailInput.prop("disabled", yes);
		this.$submitbutton.prop("disabled", yes);
	}
};

window.app = app;
jQuery(document).ready(function ($) {
	app.init();
});